// adapters/dayjs-date.adapter.ts
import { Inject, Injectable, Optional } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import 'dayjs/locale/en';
import 'dayjs/locale/nl'; // Import the Dutch locale
import 'dayjs/locale/fr'; // Import the French locale
import 'dayjs/locale/de'; // Import the German locale

@Injectable()
export class DayjsDateAdapter extends DateAdapter<dayjs.Dayjs> {
	constructor(@Optional() @Inject(MAT_DATE_LOCALE) public dateLocale: string) {
		super();
		this.initializeParser(dateLocale);
	}
	getNumDaysInMonth(date: dayjs.Dayjs): number {
		throw new Error('Method not implemented.');
	}
	toIso8601(date: dayjs.Dayjs): string {
		throw new Error('Method not implemented.');
	}
	isDateInstance(obj: any): boolean {
		throw new Error('Method not implemented.');
	}
	isValid(date: dayjs.Dayjs): boolean {
		throw new Error('Method not implemented.');
	}
	invalid(): dayjs.Dayjs {
		throw new Error('Method not implemented.');
	}
	private localeData!: {
		firstDayOfWeek: number;
		longMonths: string[];
		shortMonths: string[];
		dates: string[];
		longDaysOfWeek: string[];
		shortDaysOfWeek: string[];
		narrowDaysOfWeek: string[];
	};

	setLocale(locale: string) {
		if (locale === 'du') locale = 'nl';
		super.setLocale(locale);
		dayjs.locale(locale);
		this.updateLocaleData();
	}

	getYear(date: dayjs.Dayjs): number {
		return date.year();
	}

	getMonth(date: dayjs.Dayjs): number {
		return date.month();
	}

	getDate(date: dayjs.Dayjs): number {
		return date.date();
	}

	getDayOfWeek(date: dayjs.Dayjs): number {
		return date.day();
	}

	getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
		return style === 'long' ? this.localeData.longMonths : this.localeData.shortMonths;
	}

	getDateNames(): string[] {
		return this.localeData.dates;
	}

	getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
		if (style === 'long') {
			return this.localeData.longDaysOfWeek;
		}
		if (style === 'short') {
			return this.localeData.shortDaysOfWeek;
		}
		return this.localeData.narrowDaysOfWeek;
	}

	getYearName(date: dayjs.Dayjs): string {
		return date.format('YYYY');
	}

	getFirstDayOfWeek(): number {
		return this.localeData.firstDayOfWeek;
	}

	createDate(year: number, month: number, date: number): dayjs.Dayjs {
		return dayjs().year(year).month(month).date(date);
	}

	today(): dayjs.Dayjs {
		return dayjs();
	}
	parse(value: any, parseFormat: string | string[]): dayjs.Dayjs | null {
		if (!value) {
			return null;
		}

		// If value is already a Dayjs instance, return it
		if (dayjs.isDayjs(value)) {
			return value;
		}

		if (typeof value === 'string') {
			// Use the provided parseFormat or fallback to 'DD/MM/YYYY'
			const format = parseFormat || 'DD/MM/YYYY';

			// Parse the date with the specified format and locale
			const parsed = dayjs(value, format, this.locale, true);

			// Return the parsed date if valid, otherwise null
			return parsed.isValid() ? parsed : null;
		}

		// If value is not a string, try to parse it directly
		return dayjs(value);
	}

	format(date: dayjs.Dayjs, displayFormat: string): string {
		return date.format(displayFormat);
	}

	addCalendarYears(date: dayjs.Dayjs, years: number): dayjs.Dayjs {
		return date.add(years, 'year');
	}

	addCalendarMonths(date: dayjs.Dayjs, months: number): dayjs.Dayjs {
		return date.add(months, 'month');
	}

	addCalendarDays(date: dayjs.Dayjs, days: number): dayjs.Dayjs {
		return date.add(days, 'day');
	}

	clone(date: dayjs.Dayjs): dayjs.Dayjs {
		return date.clone();
	}

	private updateLocaleData(): void {
		super.setLocale(this.locale);
		const localeData = dayjs().localeData();
		this.localeData = {
			firstDayOfWeek: localeData.firstDayOfWeek(),
			longMonths: localeData.months(),
			shortMonths: localeData.monthsShort(),
			dates: Array.from({ length: 31 }, (_, i) => String(i + 1)),
			longDaysOfWeek: localeData.weekdays(),
			shortDaysOfWeek: localeData.weekdaysShort(),
			narrowDaysOfWeek: localeData.weekdaysMin(),
		};
	}
	private initializeParser(dateLocale: string) {
		dayjs.extend(LocalizedFormat);
		dayjs.extend(customParseFormat);
		dayjs.extend(localeData);

		// Set the locale globally
		if (dateLocale) {
			dayjs.locale(dateLocale);
		}
	}
}

import { Component, Inject, LOCALE_ID } from '@angular/core';
import { AuthenticationService, UserService } from './core/services';
import { TranslateService } from '@ngx-translate/core';
import { ResponsiveService, ScreenSize } from './core/services/admin/responsive.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SnackBar } from './core/utility/snackBar';
import { NavigationStart, Router } from '@angular/router';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/de';
import 'dayjs/locale/cs';
import 'dayjs/locale/en';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import { DateAdapter } from '@angular/material/core';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	currentUser: any;
	maintenance: boolean;
	bypass: boolean;
	currentUrl: string;
	routerSubsription;

	constructor(
		@Inject(LOCALE_ID) private localeId: string,
		private authenticationService: AuthenticationService,
		private userService: UserService,
		private translateService: TranslateService,
		private responsiveService: ResponsiveService,
		private snackBar: SnackBar,
		private dateAdapter: DateAdapter<any>,
		router: Router,
		breakpointObserver: BreakpointObserver
	) {
		this.routerSubsription = router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				const localUrl = router.getCurrentNavigation().extractedUrl.toString();
				if (localUrl !== this.currentUrl) {
					this.currentUrl = localUrl;
					if (!this.currentUrl.includes('account') && this.currentUrl !== '/') {
						localStorage.setItem('_QR_CURRENT_URL', this.currentUrl);
					}
				}
			}
		});

		this.translateService.addLangs(['cs', 'en', 'es', 'fr', 'nl', 'de']);
		this.translateService.onLangChange.subscribe((event) => {
			if (event.lang === 'du') {
				event.lang = 'nl';
			}
			dayjs.locale(event.lang);
			this.dateAdapter.setLocale(event.lang);
		});

		this.authenticationService.currentUser.subscribe((currentUser) => {
			this.currentUser = currentUser;
			if (currentUser) {
				this.userService.getUser().valueChanges.subscribe(
					(user) => {
						this.userService.nextUserProfile(user.data.getUser.userData);
						const lang = this.userService.getUserProfile.language;
						if (lang) {
							this.translateService.use(lang);
						}
					},
					(err) => this.snackBar.openSnackBarError(err.message)
				);
				// get url from local storage and navigate to it
				const url = localStorage.getItem('_QR_CURRENT_URL');
				if (url && !url.includes('account') && url !== '/') {
					router.navigate([url]);
				} else {
					router.navigate(['/dashboard']);
				}
			}
		});

		// Observe screenSize and let other components know through the responsive service
		breakpointObserver.observe([Breakpoints.XSmall]).subscribe((result) => {
			if (result.matches) this.responsiveService.changeScreenSize(ScreenSize.XSMALL);
		});
		breakpointObserver.observe([Breakpoints.Small]).subscribe((result) => {
			if (result.matches) this.responsiveService.changeScreenSize(ScreenSize.SMALL);
		});
		breakpointObserver.observe([Breakpoints.Medium]).subscribe((result) => {
			if (result.matches) this.responsiveService.changeScreenSize(ScreenSize.MEDIUM);
		});
		breakpointObserver.observe([Breakpoints.Large]).subscribe((result) => {
			if (result.matches) this.responsiveService.changeScreenSize(ScreenSize.LARGE);
		});
		breakpointObserver.observe([Breakpoints.XLarge]).subscribe((result) => {
			if (result.matches) this.responsiveService.changeScreenSize(ScreenSize.XLARGE);
		});
		// breakpointObserver.observe(['(min-width: 500px)']).subscribe((result) => {
		// 	if (result.matches) this.responsiveService.changeScreenHeightSize()
		// })
	}
}
